import getData from "@/__main__/get-data.mjs";
import LolProbuildMatches from "@/game-lol/models/lol-probuild-matches.mjs";
import * as API from "@/game-lol/utils/api.mjs";

function fetchData(params) {
  const [teamId] = params;

  if (!teamId) return;

  return getData(
    API.getProMatchHistory({
      first: 10,
      teamIds: [teamId],
    }),
    LolProbuildMatches,
    ["lol", "proBuildTeamMatchlist", teamId],
    {
      shouldFetchIfPathExists: true,
    },
  );
}

export default fetchData;
